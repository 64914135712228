<template>
  <DialogWrapper
    v-model="model"
    width="396"
  >
    <VContainer class="pa-0">
      <VRow
        justify="space-between"
        align="center"
        no-gutters
      >
        <VCol cols="auto">
          <div
            class="tt-text-subtitle"
            data-test="confirmation-dialog-title"
          >
            {{ title }}
          </div>
        </VCol>
        <VCol cols="auto">
          <TTBtn
            text
            min-width="auto"
            class="pa-0"
            data-test-label="confirmation-dialog-button-close"
            @click="handleAction('cancelAction')"
          >
            <VIcon
              color="tt-black"
              size="19"
            >
              fal fa-times
            </VIcon>
          </TTBtn>
        </VCol>
      </VRow>
      <VRow dense>
        <VCol>
          <div
            class="tt-text-body-2"
            data-test="confirmation-dialog-description"
          >
            {{ description }}
          </div>
        </VCol>
      </VRow>
      <VRow
        v-if="cancelAction || confirmAction"
        justify="end"
      >
        <VCol
          v-if="cancelAction"
          cols="auto"
        >
          <TTBtn
            data-test-label="confirmation-dialog-button-cancel"
            :color="cancelButtonColor"
            @click="handleAction('cancelAction')"
          >
            {{ cancelButtonText }}
          </TTBtn>
        </VCol>
        <VCol
          v-if="confirmAction"
          cols="auto"
        >
          <TTBtn
            :loading="loading"
            data-test-label="confirmation-dialog-button-confirm"
            depressed
            :color="confirmButtonColor"
            @click="handleAction('confirmAction')"
          >
            {{ confirmButtonText }}
          </TTBtn>
        </VCol>
      </VRow>
    </VContainer>
  </DialogWrapper>
</template>

<script>
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

const DIALOG = {
  title: '',
  description: '',
  model: false,
  confirmAction: false,
  confirmButtonText: 'Подтвердить',
  confirmButtonColor: 'tt-primary',
  cancelAction: () => {},
  cancelButtonText: 'Отменить',
  cancelButtonColor: 'tt-secondary-danger',
};
export default {
  name: 'ConfirmationDialog',
  components: { DialogWrapper },
  data() {
    return {
      ...DIALOG,
      loading: false,
    };
  },
  watch: {
    model(value) {
      if (!value) {
        this.clearConfirmationDialog();
      }
    },
  },
  mounted() {
    this.$root.$on('showConfirmation', this.showConfirmation);
  },
  beforeDestroy() {
    this.$root.$off('showConfirmation', this.showConfirmation);
  },
  methods: {
    clearConfirmationDialog() {
      Object.keys(DIALOG)
        .forEach((key) => {
          this[key] = DIALOG[key];
        });
    },
    async handleAction(action) {
      try {
        this.loading = true;
        const clonedAction = this[action];
        this.clearConfirmationDialog();
        await clonedAction();
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    /**
     * Функция для вызова confirmation dialog
     * @param title - is for title of confirmation dialog
     * @param description - is for description of confirmation dialog
     * @param cancelAction - is for call function by cancel click
     * @param cancelButtonText - is for cancel button text
     * @param cancelButtonColor - is for cancel button color
     * @param confirmAction - is for call function by confirm button click
     * @param confirmButtonText - is for confirm button text
     * @param confirmButtonColor - is for confirm button color
     * @type {
     * title:string,
     * description:string,
     * cancelAction:function,
     * cancelButtonText:string,
     * cancelButtonColor:string,
     * confirmAction:function,
     * confirmButtonText:string,
     * confirmButtonColor:string
     * }
     */
    showConfirmation({
      title,
      description,
      cancelAction = DIALOG.cancelAction,
      cancelButtonText = DIALOG.cancelButtonText,
      cancelButtonColor = DIALOG.cancelButtonColor,
      confirmAction = DIALOG.confirmAction,
      confirmButtonText = DIALOG.confirmButtonText,
      confirmButtonColor = DIALOG.confirmButtonColor,
    }) {
      this.title = title;
      this.description = description;
      this.cancelAction = cancelAction;
      this.cancelButtonText = cancelButtonText;
      this.cancelButtonColor = cancelButtonColor;
      this.confirmAction = confirmAction;
      this.confirmButtonText = confirmButtonText;
      this.confirmButtonColor = confirmButtonColor;
      this.$nextTick(() => {
        this.model = true;
      });
    },
  },
};
</script>

<style scoped>

</style>
