<template>
  <TTNotify
    v-model="model"
    :type="type"
    :timeout="timeout"
    top
    right
  >
    <VContainer class="pa-0">
      <VRow no-gutters>
        <VCol
          v-if="title"
          class="tt-text-body-1 font-weight-medium"
          cols="12"
        >
          {{ title }}
        </VCol>
        <VCol
          v-if="msg"
          class="tt-text-body-2"
          cols="12"
        >
          {{ msg }}
        </VCol>
      </VRow>
      <VRow
        v-if="cancelAction || submitAction"
        dense
        justify="end"
      >
        <VCol
          v-if="cancelAction"
          cols="auto"
        >
          <TTBtn
            color="tt-primary"
            data-test="staff--button-close"
            text
            @click="handleAction('cancelAction')"
          >
            {{ cancelText }}
          </TTBtn>
        </VCol>
        <VCol
          v-if="submitAction"
          cols="auto"
        >
          <TTBtn
            :loading="loading"
            data-test="staff--button-submit"
            depressed
            @click="handleAction('submitAction')"
          >
            {{ submitText }}
          </TTBtn>
        </VCol>
      </VRow>
    </VContainer>
  </TTNotify>
</template>

<script>

const SNACKBAR = {
  title: '',
  timeout: 6000,
  type: 'info',
  msg: '',
  model: false,
  submitAction: false,
  submitText: 'Отправить',
  cancelAction: false,
  cancelText: 'Отменить',
};
export default {
  name: 'Notifier',
  data() {
    return {
      ...SNACKBAR,
      loading: false,
    };
  },
  watch: {
    model(value) {
      if (!value) {
        this.clearSnackbar();
      }
    },
  },
  mounted() {
    this.$root.$on('showNotifier', this.showNotifier);
  },
  beforeDestroy() {
    this.$root.$off('showNotifier', this.showNotifier);
  },
  methods: {
    clearSnackbar() {
      Object.keys(SNACKBAR).forEach((key) => {
        this[key] = SNACKBAR[key];
      });
    },
    async handleAction(action) {
      try {
        this.loading = true;
        const clonedAction = this[action];
        this.clearSnackbar();
        await clonedAction();
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    showNotifier({
      title,
      msg,
      type = SNACKBAR.type,
      timeout = SNACKBAR.timeout,
      cancelAction = SNACKBAR.cancelAction,
      cancelText = SNACKBAR.cancelText,
      submitAction = SNACKBAR.submitAction,
      submitText = SNACKBAR.submitText,
    }) {
      this.title = title;
      this.msg = msg;
      this.type = type;
      this.timeout = timeout;
      this.cancelAction = cancelAction;
      this.cancelText = cancelText;
      this.submitAction = submitAction;
      this.submitText = submitText;
      this.$nextTick(() => {
        this.model = true;
      });
    },
  },
};
</script>

<style scoped>

</style>
