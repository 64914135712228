<template>
  <VApp id="HolyGrailLayout">
    <RouterView name="nav" />

    <RouterView name="aside-left" />

    <VMain>
      <!-- TODO: добавить кнопки и TTView-->
      <TTView>
        <template #left>
          <VContainer
            fluid
            class="pa-0 text-center"
          >
            <VRow
              justify="center"
            >
              <VCol cols="auto">
                <TTBtn
                  fab
                  color="white"
                  elevation="1"
                  depressed
                  large
                  class="mb-2"
                  @click="$router.go(-1)"
                >
                  <VIcon
                    color="tt-black"
                    size="19"
                  >
                    fal fa-arrow-left
                  </VIcon>
                </TTBtn>
                <div class="tt-text-caption tt-black--text text--lighten-2 mb-6 d-block">
                  Назад
                </div>
                <VSpacer />
              </VCol>
            </VRow>
          </VContainer>
        </template>
        <VRow
          v-if="$vuetify.breakpoint.xsOnly"
          class="pa-12"
        >
          <VCol cols="12">
            <h1 class="mb-3 tt-text-headline-1">
              Ой...
            </h1>

            <p class="mb-0 text-body-2 tt-black--text text--lighten-2">
              Администрирование доступно только с декстопной версии. Для доступа, пожалуйста, зайдите с компьютера.
            </p>
          </VCol>
        </VRow>
        <RouterView v-else />
        <template #right>
          <RouterView name="tt-view-right" />
        </template>
      </TTView>
      <RouterView name="aside-right" />
      <RouterView name="content-footer" />
      <Popover
        v-model="showPopoverHint"
        title="Удобная поддержка пользователей"
        content-class="container-help-hint"
      >
        <p>
          Задавайте любые вопросы в поддержку по почте <a href="mailto:help@talenttech.ru">help@talenttech.ru</a>
          или в чате прямо здесь, в правом нижнем углу.
        </p>

        <p>
          Получите решение быстрее!<br>
          Мы проконсультируем по возможностям админки и приложения, поможем с доступами и авторизацией,
          решим технические сложности и примем вашу обратную связь по продукту TalentTech Адаптация.
        </p>
      </Popover>
    </VMain>
    <Notifier />
    <ConfirmationDialog />
    <RouterView
      name="footer"
    />
  </VApp>
</template>

<script>
import Notifier from '@/components/notifier/Notifier.vue';
import Popover from '@/components/shared/Popover.vue';
import { mapActions, mapGetters } from 'vuex';
import { helpDesk, usedesk } from '@/plugins';

import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

export default {
  name: 'HolyGrailLayout',
  components: {
    ConfirmationDialog,
    Notifier,
    Popover,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('acl', ['user']),
    ...mapGetters(['showUsedeskHint']),
    showPopoverHint: {
      get() {
        return this.showUsedeskHint;
      },
      set(val) {
        this.setShowUsedeskHint(val);
      },
    },
    useHelpdesk() {
      return this.$di.ff.get('helpdesk');
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    ...mapActions(['setShowUsedeskHint']),
    /* Инициализация приложения переехала в vue-router/index.js */
    async init() {
      try {
        if (this.useHelpdesk) {
          helpDesk(this.user, '//cdn5.helpdeskeddy.com//js/modules/chat_widget/hde.js');
        } else {
          usedesk(this.user, [], () => {
            this.setShowUsedeskHint(false);
          });
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>
<style>
/** Фиксированные кнопки слева и справа */
#HolyGrailLayout .container > .row > .d-none.d-sm-flex {
  position: sticky;
  top: 0;
}

.container-help-hint {
  position: fixed;
  right: -8px;
  bottom: 60px;
}
</style>
