<template>
  <DialogWrapper
    :value="show"
    width="396"
    eager
    :content-class="['dialog-wrapper-popover', contentClass].join(' ')"
    @click:outside="handleClose"
  >
    <VContainer
      class="pa-0"
      fluid
    >
      <VRow>
        <VCol>
          <h3
            class="tt-text-subtitle tt-light-mono-100--text"
            data-test="popover-title"
          >
            {{ title }}
          </h3>
        </VCol>
      </VRow>
      <VRow class="mt-0">
        <VCol
          class="tt-text-body-2 tt-light-mono-100--text"
          data-test="popover-body"
        >
          <slot />
        </VCol>
      </VRow>
      <TTBtn
        absolute
        top
        right
        data-test-label="popover-button-close"
        width="20"
        height="20"
        min-width="20"
        class="pa-0"
        depressed
        color="transparent"
        @click="handleClose"
      >
        <VIcon
          color="tt-black"
          size="19"
        >
          fal fa-times
        </VIcon>
      </TTBtn>
    </VContainer>
    <VIcon
      color="tt-white"
      size="45"
      class="icon-caret"
    >
      fa fa-caret-down
    </VIcon>
  </DialogWrapper>
</template>
<script >
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'Popover',
  components: {
    DialogWrapper,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    contentClass: {
      type: String,
      default: null,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleClose() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss">
.dialog-wrapper-popover {
  overflow-y:unset;
  .icon-caret {
    position: absolute;
    right: 25px;
    bottom: -25px;
  }
}
</style>
